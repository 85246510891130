import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { captureException } from '@sentry/react';
import toast from 'react-hot-toast';
import useSWR from 'swr';

import { useTeam } from '@/app/team/context/TeamContext';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { formatDate } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as CategoryTemplatesResponseType } from '../endpoints/ListCategoryTemplatesEndpoint';
import type { BodyType as CreateCategoryTemplateBody } from '../endpoints/CreateCategoryTemplateEndpoint';
import { CategoryTemplateFormDialog } from './CategoryTemplateDialog';
import { getDisplayError } from '@/utils/get-display-error';

export const CategoryTemplatesPage = () => {
  const { team } = useTeam();
  const [refreshToken, setRefreshToken] = useState(Date.now());
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const { data, isLoading } = useSWR<CategoryTemplatesResponseType>(
    `/api/v1/category-template/list?teamId=${team.id}&hash=${refreshToken}`,
    fetchEndpointData,
  );

  const templates = data?.items ?? [];
  const title = 'Category Templates';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />
      </div>

      <CategoryTemplateFormDialog
        isOpen={showCreateDialog}
        setIsOpen={setShowCreateDialog}
        title="Create category template"
        submitText="Create"
        onSubmit={async (values, helpers) => {
          try {
            const payload: CreateCategoryTemplateBody = {
              teamId: team.id,
              data: {
                name: values.name,
              },
            };
            await fetchEndpointData('/api/v1/category-template/create', {
              method: 'POST',
              body: payload,
            });
            setRefreshToken(Date.now());
            helpers.resetForm();
            toast.success('Category created');
          } catch (err) {
            captureException(err);
            toast.error(`Failed to create category: ${getDisplayError(err)}`);
            throw err;
          }
        }}
      />

      {!templates.length && isLoading ? (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          <div
            className="card flex justify-center items-center gap-2 text-lg font-medium"
            data-clickable="true"
            onClick={() => {
              setShowCreateDialog(true);
            }}
          >
            <div className="flex-shrink-0">
              <PlusIcon />
            </div>
            Create Category Template
          </div>

          {templates.map((v) => {
            return (
              <Link to={v.id} key={v.id} className="card" data-clickable="true">
                <div className="text-lg font-medium">{v.name}</div>
                <div>{formatDate(v.createdAt)}</div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
