import useSWR from 'swr';
import { useMemo } from 'react';
import { useField } from 'formik';

import { InputWrapper } from '@/components/InputWrapper';
import { SimpleSelect } from '@/components/select/SimpleSelect';
import { useTeam } from '@/app/team/context/TeamContext';
import { fetchEndpointData } from '@/utils/fetch.client';
import { Spinner } from '@/components/Spinner';
import type { ResponseType as UsersResponse } from '../endpoints/TeamUsersEndpoint';

export interface ITeamUserSelectProps {
  value: string | null;
  onSelect: (value: string | null) => void;
  labelText: string;
  helperText?: string;
  isDisabled?: boolean;
  invalidText?: string;
  isInvalid?: boolean;
  onBlur?: () => void;
}

export const TeamUserSelect: React.FC<ITeamUserSelectProps> = (props) => {
  const { value, onSelect, labelText, helperText, isDisabled, invalidText, isInvalid, onBlur } = props;
  const { team } = useTeam();
  const { data, isLoading } = useSWR<UsersResponse>(
    `/api/v1/team/user/list?teamId=${team.id}&take=500`,
    fetchEndpointData,
  );

  const userOptions = useMemo(() => {
    const users = data?.docs ?? [];

    return users.map((user) => ({
      key: user.id,
      name: user.name,
    }));
  }, [data]);

  if (isLoading) {
    return <Spinner size={6} />;
  }

  return (
    <InputWrapper labelText={labelText} invalidText={invalidText} helperText={helperText} noLabel>
      <SimpleSelect
        items={userOptions}
        selectedItem={userOptions.find((v) => v.key === value) ?? null}
        onSelect={(val) => {
          onSelect(val?.key ?? null);
        }}
        onBlur={onBlur}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
      />
    </InputWrapper>
  );
};

export interface ITeamUserSelectFieldProps {
  name: string;
  labelText: string;
  helperText?: string;
  isDisabled?: boolean;
  invalidText?: string;
}

export const TeamUserSelectField: React.FC<ITeamUserSelectFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled, invalidText } = props;
  const [field, meta, helpers] = useField({ name });

  return (
    <TeamUserSelect
      value={field.value}
      onSelect={helpers.setValue}
      labelText={labelText}
      helperText={helperText}
      isDisabled={isDisabled}
      invalidText={invalidText}
      isInvalid={Boolean(meta.touched && meta.error)}
      onBlur={() => helpers.setTouched(true)}
    />
  );
};
