import { ErrorCode } from './ErrorCodes';

export class CustomError extends Error {
  code: ErrorCode;
  validation: Array<any> | undefined;

  constructor(msg: string, code: ErrorCode) {
    super(msg);
    this.code = code;
  }
}

export class PermissionDeniedError extends CustomError {
  constructor() {
    super('Permission denied', ErrorCode.PERMISSION_DENIED);
  }
}

export function getErrorForClient(err: any): {
  message: string;
  code: ErrorCode;
  validation?: any[];
} {
  if (err instanceof CustomError) {
    return {
      message: err.message,
      code: err.code,
      validation: err.validation,
    };
  }

  return {
    message: 'An unexpected error occurred',
    code: ErrorCode.UNEXPECTED_ERROR,
  };
}
