import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from '@/components/Breadcrumb';
import { PageHeader } from '@/components/PageHeader';

export interface ICardButton {
  title: string;
  to: string;
}

const CardButton: React.FC<ICardButton> = (props) => {
  const { to, title } = props;

  return (
    <Link to={to} className="card" data-clickable="true">
      <div className="text-lg font-medium">{title}</div>
    </Link>
  );
};

export const BackofficePage = () => {
  return (
    <div className="page-content">
      <PageHeader title="INTERNAL Backoffice" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Backoffice',
            },
          ]}
        />
      </div>

      <div className="grid grid-cols-4 gap-4">
        <CardButton to="/app/internal/teams" title="Teams" />
        <CardButton to="/app/internal/audit-logs" title="Audit logs" />
      </div>
    </div>
  );
};
