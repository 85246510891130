import { createId } from '@paralleldrive/cuid2';
import { useEffect, useMemo, useReducer, useState } from 'react';

export interface IPaginationVariables {
  page: number;
  take: number;
}

export interface IPaginationReturnValue<T> {
  variables: IPaginationVariables;
  pages: number;
  hasNext: boolean;
  next: () => void;
  hasPrevious: boolean;
  previous: () => void;
  data: T[];
  error?: Error;
  isFetching: boolean;
  reset: () => void;
}

export type PageDataFetcherFn<T> = (variables: IPaginationVariables) => Promise<{
  data: T[];
  pages: number;
}>;

export interface IUsePaginationProps<T> {
  fetcher: PageDataFetcherFn<T>;
  pageSize: number;
  page: number;
  refreshToken?: any;
}

interface IPaginationState {
  isFetching: boolean;
  fetchToken: string;
  data: any[];
  pages: number;
  error?: Error;
}

type IPaginationAction =
  | {
      type: 'fetchStarted';
      token: string;
    }
  | {
      type: 'fetchCompleted';
      token: string;
      data: any[];
      pages: number;
    }
  | {
      type: 'fetchFailed';
      token: string;
      error: Error;
    };

function paginationReducer<T>(state: IPaginationState, action: IPaginationAction): IPaginationState {
  switch (action.type) {
    case 'fetchStarted': {
      return {
        ...state,
        fetchToken: action.token,
        isFetching: true,
      };
    }
    case 'fetchCompleted': {
      if (action.token !== state.fetchToken) {
        return state;
      }

      return {
        ...state,
        pages: action.pages,
        data: action.data,
        isFetching: false,
      };
    }
    case 'fetchFailed': {
      if (action.token !== state.fetchToken) {
        return state;
      }

      return {
        ...state,
        data: [],
        error: action.error,
        isFetching: false,
      };
    }
  }
}

export function usePagination<T>(props: IUsePaginationProps<T>): IPaginationReturnValue<T> {
  const { fetcher, pageSize, page: _page, refreshToken } = props;
  const [variables, setVariables] = useState<IPaginationVariables>(() => {
    if (isNaN(_page)) {
      return {
        page: 0,
        take: pageSize,
      };
    } else {
      return {
        page: _page,
        take: pageSize,
      };
    }
  });
  const [state, dispatch] = useReducer(paginationReducer, {
    isFetching: false,
    fetchToken: '',
    data: [],
    error: undefined,
    pages: 1,
  });

  useEffect(() => {
    if (isNaN(_page)) {
      return;
    }

    setVariables((prev) => {
      if (prev.page !== _page) {
        return {
          ...prev,
          page: _page,
        };
      } else {
        return prev;
      }
    });
  }, [_page]);

  const entries = useMemo(() => {
    const cloned = [...state.data];
    if (state.data.length > pageSize) {
      cloned.pop();

      if (cloned.length > pageSize) {
        cloned.shift();
      }
    }
    return cloned;
  }, [state.data]);

  useEffect(() => {
    const token = createId();

    dispatch({
      type: 'fetchStarted',
      token,
    });

    fetcher(variables)
      .then((val) => {
        dispatch({
          type: 'fetchCompleted',
          data: val.data,
          pages: val.pages,
          token,
        });
      })
      .catch((err) => {
        dispatch({
          type: 'fetchFailed',
          error: err,
          token,
        });
      });
  }, [variables, refreshToken, fetcher]);

  const page = variables.page;
  const hasNext = page < state.pages - 1;
  const next = () => {
    if (!hasNext) return;

    setVariables({
      page: page + 1,
      take: pageSize,
    });
  };

  const hasPrevious = page > 0;
  const previous = () => {
    if (!hasPrevious) return;

    setVariables({
      page: page - 1,
      take: pageSize,
    });
  };

  return {
    variables,
    pages: state.pages,
    hasNext,
    next,
    hasPrevious,
    previous,
    data: entries,
    isFetching: state.isFetching,
    reset: () => {
      setVariables({
        page: 0,
        take: pageSize,
      });
    },
  };
}
