import {
  PencilSimple as PencilIcon,
  Plus as PlusIcon,
  Trash as TrashIcon,
  CaretDown as CaretDownIcon,
  CaretRight as CaretRightIcon,
} from '@phosphor-icons/react';
import toast from 'react-hot-toast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { ICategoryNode, useWorkspaceCategories } from '../contexts/WorkspaceCategoriesContext';
import { CategoryFormDialog } from './CategoryFormDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { Button } from '../../../components/button/Button';
import { CategoryDeleteDialog } from './CategoryDeleteDialog';
import {
  BodyType as CreateCategoryPayload,
  ResponseType as CreateCategoryResponseType,
} from '../endpoints/CreateWorkspaceCategoryEndpoint';
import { BodyType as UpdateCategoryPayload } from '../endpoints/UpdateWorkspaceCategoryEndpoint';
import { BodyType as DeleteCategoryPayload } from '../endpoints/DeleteWorkspaceCategoryEndpoint';
import { BodyType as ImportCategoryTemplatePayload } from '../endpoints/ImportCategoryTemplateEndpoint';
import { BodyType as ScheduleClassificationPayload } from '../endpoints/ScheduleCategoryClassificationEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { captureException } from '@sentry/react';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { Tag } from '@/components/Tag';
import { FormDialog } from '@/components/dialog/FormDialog';
import { CategoryTemplateSelectField } from '@/app/category/components/CategoryTemplateSelect';
import { nullthrows } from '@/utils/invariant';
import { ConfirmDialog } from '@/components/dialog/ConfirmDialog';
import { useCategoryTreeReducer } from '@/app/category/hooks/useCategoryTreeReducer';
import { CategoriesFolderDrop, ITreeNode } from '@/app/category/components/CategoriesFolderDrop';
import { Spinner } from '@/components/Spinner';

export interface ICategoryNodeProps {
  idx: string;
  parentIdx: string;
  node: ICategoryNode;
  depth: number;
  openCategories: Set<string>;
  isOpen: boolean;
  toggleOpen: (categoryId: string) => void;
}

const CategoryNode: React.FC<ICategoryNodeProps> = (props) => {
  const { idx, parentIdx, node, depth, isOpen, openCategories, toggleOpen } = props;

  const { workspace, tree, treeKey } = useWorkspace();

  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const excludedCategoryIds = useMemo(() => {
    return [node.id];
  }, [node.id]);

  const documentCount = useMemo(() => {
    return tree.getDocumentsInCategory(node.id).length;
  }, [treeKey]);

  const fullIdx = parentIdx ? `${parentIdx}.${idx}` : idx;
  return (
    <div>
      <div
        className="flex gap-2 justify-between items-center border-b border-gray-200 py-1 hover:bg-gray-100 cursor-pointer"
        style={{
          paddingLeft: `${depth * 1}rem`,
        }}
        onClick={() => {
          toggleOpen(node.id);
        }}
      >
        <div>{isOpen ? <CaretDownIcon className="w-4 h-4" /> : <CaretRightIcon className="w-4 h-4" />}</div>
        <div className="flex flex-1 items-center gap-2">
          <div>{`${fullIdx} ${node.name}`}</div>
          <div className="flex gap-2">{documentCount > 0 && <Tag color="blue">{documentCount} documents</Tag>}</div>
        </div>
        <div
          className="flex gap-1"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowEditDialog(true);
            }}
          >
            <PencilIcon className="w-4 h-4" />
          </Button>
          <Button
            size={6}
            shape="square"
            onTrigger={() => {
              setShowDeleteDialog(true);
            }}
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
          <CategoryFormDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-edit`}
            excludedCategoryIds={excludedCategoryIds}
            initialValues={{
              name: node.name,
              description: node.description,
              parentCategoryId: node.parentCategoryId,
            }}
            isOpen={showEditDialog}
            setIsOpen={setShowEditDialog}
            title="Edit category"
            submitText="Save"
            onSubmit={async (values) => {
              try {
                const payload: UpdateCategoryPayload = {
                  categoryId: node.id,
                  workspaceId: workspace.id,
                  data: {
                    name: values.name,
                    description: values.description,
                    parentCategoryId: values.parentCategoryId,
                  },
                };
                await fetchEndpointData('/api/v1/workspace/category/update', {
                  method: 'POST',
                  body: payload,
                });
                toast.success('Category updated');
              } catch (err) {
                captureException(err);
                toast.error(`Failed to update category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
          <CategoryDeleteDialog
            key={`${node.id}-${node.name}-${node.parentCategoryId}-delete`}
            excludedCategoryIds={excludedCategoryIds}
            initialValues={{
              newCategoryId: null,
            }}
            isOpen={showDeleteDialog}
            setIsOpen={setShowDeleteDialog}
            categoryName={node.name}
            onSubmit={async (values) => {
              try {
                const payload: DeleteCategoryPayload = {
                  workspaceId: workspace.id,
                  categoryId: node.id,
                  newCategoryId: values.newCategoryId,
                };
                await fetchEndpointData('/api/v1/workspace/category/delete', {
                  method: 'DELETE',
                  body: payload,
                });
                toast.success('Category removed');
              } catch (err) {
                captureException(err);
                toast.error(`Failed to delete category: ${getDisplayError(err)}`);
                throw err;
              }
            }}
          />
        </div>
      </div>

      {isOpen && (
        <div>
          {node.children.map((v, idx) => {
            return (
              <CategoryNode
                key={v.id}
                node={v}
                depth={depth + 1}
                parentIdx={fullIdx}
                idx={`${idx + 1}`}
                openCategories={openCategories}
                toggleOpen={toggleOpen}
                isOpen={openCategories.has(v.id)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const createCategory = async (data: {
  workspaceId: string;
  name: string;
  description: string;
  parentCategoryId: string | null;
}) => {
  const { workspaceId, name, description, parentCategoryId } = data;
  const payload: CreateCategoryPayload = {
    data: {
      workspaceId,
      name,
      description,
      parentCategoryId,
    },
  };
  const result = await fetchEndpointData<CreateCategoryResponseType>('/api/v1/workspace/category/create', {
    method: 'POST',
    body: payload,
  });
  return result.category;
};

const handleFolderImport = async (workspaceId: string, folder: ITreeNode, parentCategoryId: string | null) => {
  const createdCategory = await createCategory({
    workspaceId,
    name: folder.name,
    description: '',
    parentCategoryId,
  });

  if (folder.children) {
    for (const child of folder.children) {
      await handleFolderImport(workspaceId, child, createdCategory.id);
    }
  }
};

export const WorkspaceCategoriesPage = () => {
  const { workspace } = useWorkspace();
  const { categories, categoryTree: _categoryTree } = useWorkspaceCategories();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [createKey, setCreateKey] = React.useState(0);
  const [isImporting, setIsImporting] = useState(false);
  const [{ categoryTree, openCategories }, dispatch] = useCategoryTreeReducer(_categoryTree);

  useEffect(() => {
    dispatch({
      type: 'set_category_tree',
      categoryTree: _categoryTree,
    });
  }, [_categoryTree]);

  const toggleOpen = useCallback(
    (categoryId: string) => {
      dispatch({
        type: 'toggle',
        categoryId,
      });
    },
    [dispatch],
  );

  const initialValues = useMemo(() => {
    return {
      templateId: null as null | string,
    };
  }, []);

  const categoryCount = categories.length;
  const handleDropImport = useCallback(
    async (folders: ITreeNode[]) => {
      if (categoryCount > 0) {
        return;
      }

      if (folders.length > 0) {
        setIsImporting(true);
        for (const folder of folders) {
          try {
            await handleFolderImport(workspace.id, folder, null);
          } catch (err) {
            setIsImporting(false);
            toast.error(`Failed to import folder: ${getDisplayError(err)}`);
          }
        }
        setIsImporting(false);
      }
    },
    [categoryCount],
  );

  return (
    <div className="page-content">
      <PageHeader title="Categories" />

      <div>
        <div className="mb-4 flex justify-between">
          <Breadcrumb
            items={[
              {
                name: 'Categories',
              },
            ]}
          />

          <div className="flex gap-2">
            <ConfirmDialog
              triggerText="Rerun auto-categorisation"
              title="Rerun auto-categorisation"
              description="Are you sure you want to re-run the auto-categorisation?"
              submitText="Re-run"
              isDisabled={categories.length <= 5}
              onSubmit={async () => {
                try {
                  const payload: ScheduleClassificationPayload = {
                    workspaceId: workspace.id,
                  };
                  await fetchEndpointData('/api/v1/workspace/category/schedule-classification', {
                    method: 'POST',
                    body: payload,
                  });
                  toast.success('Auto-categorisation started');
                } catch (err) {
                  captureException(err);
                  toast.error(`Failed to start auto-categorisation: ${getDisplayError(err)}`);
                  throw err;
                }
              }}
            />
            <FormDialog
              triggerText="Import Template"
              title="Import Template"
              submitText="Import"
              onSubmit={async (values) => {
                try {
                  const payload: ImportCategoryTemplatePayload = {
                    workspaceId: workspace.id,
                    templateId: nullthrows(values.templateId, 'Template not selected'),
                  };
                  await fetchEndpointData(`/api/v1/workspace/category/import-template`, {
                    method: 'POST',
                    body: payload,
                  });
                  toast.success('Category template imported');
                } catch (err) {
                  captureException(err);
                  toast.error('Could not import category template: ' + getDisplayError(err));
                  throw err;
                }
              }}
              initialValues={initialValues}
            >
              <CategoryTemplateSelectField labelText="Category Template" name="templateId" />
            </FormDialog>
          </div>
        </div>

        {categoryCount === 0 && <CategoriesFolderDrop onDrop={handleDropImport} />}

        {isImporting ? (
          <div className="w-full h-64 flex flex-col items-center justify-center">
            <Spinner size={12} />
          </div>
        ) : (
          <div>
            <div
              className="flex justify-between gap-2 items-center border-b border-gray-200 py-1 cursor-pointer"
              onClick={() => {
                dispatch({
                  type: 'toggle_all',
                });
              }}
            >
              <div>{openCategories.size > 0 ? <CaretDownIcon /> : <CaretRightIcon />}</div>
              <div className="font-medium flex-1">Name</div>
              <div
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
              >
                <Button
                  size={6}
                  shape="square"
                  onTrigger={() => {
                    setShowCreateDialog(true);
                  }}
                >
                  <PlusIcon className="w-4 h-4" />
                </Button>
                <CategoryFormDialog
                  key={`category-create-${createKey}`}
                  isOpen={showCreateDialog}
                  setIsOpen={setShowCreateDialog}
                  title="Create category"
                  submitText="Create"
                  onSubmit={async (values, helpers) => {
                    try {
                      const payload: CreateCategoryPayload = {
                        data: {
                          workspaceId: workspace.id,
                          name: values.name,
                          description: values.description,
                          parentCategoryId: values.parentCategoryId,
                        },
                      };
                      await fetchEndpointData('/api/v1/workspace/category/create', {
                        method: 'POST',
                        body: payload,
                      });
                      setCreateKey(Date.now());
                      helpers.resetForm();
                      toast.success('Category created');
                    } catch (err) {
                      captureException(err);
                      toast.error(`Failed to create category: ${getDisplayError(err)}`);
                      throw err;
                    }
                  }}
                />
              </div>
            </div>

            <div>
              {!categories.length && <div className="my-2">No categories found</div>}
              <div>
                {categoryTree.map((v, idx) => {
                  return (
                    <CategoryNode
                      key={v.id}
                      node={v}
                      depth={0}
                      parentIdx=""
                      idx={`${idx + 1}`}
                      openCategories={openCategories}
                      isOpen={openCategories.has(v.id)}
                      toggleOpen={toggleOpen}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
