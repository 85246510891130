import { createId } from '@paralleldrive/cuid2';
import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Coins as CoinsIcon } from '@phosphor-icons/react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { LinkButton } from '../../../components/button/Button';
import { Pagination } from '../../../components/Pagination';
import { formatDateTime } from '../../../utils/date';
import { Input } from '../../../components/input/Input';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as InternalTeamsResponseType } from '../endpoints/InternalTeamsEndpoint';
import { usePagination, IPaginationVariables } from '@/hooks/usePagination';

export const InternalTeamsPage = () => {
  const [refreshToken, _setRefreshToken] = useState(() => createId());
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, _setName] = useState(searchParams.get('name') ?? '');
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const params = new URLSearchParams();
      params.set('page', variables.page.toString(10));
      params.set('take', variables.take.toString());
      params.set('name', name);

      const result = await fetchEndpointData<InternalTeamsResponseType>(
        `/api/v1/internal/team/list?${params.toString()}`,
      );
      return {
        data: result.docs ?? [],
        pages: result.pagination.pages,
      };
    },
    [name],
  );
  const pagination = usePagination({
    pageSize: 40,
    refreshToken,
    page: +(searchParams.get('page') || 0),
    fetcher: pageFetcher,
  });

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('page', pagination.variables.page.toString(10));
      return prev;
    });
  }, [pagination.variables.page]);

  const setName = (val: string) => {
    setSearchParams((prev) => {
      prev.set('name', val);
      return prev;
    });

    _setName(val);
  };

  const teams = pagination.data ?? [];
  return (
    <div className="page-content">
      <PageHeader title="INTERNAL Teams" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Backoffice',
              to: '/app/internal',
            },
            {
              name: 'Teams',
            },
          ]}
        />

        <div className="flex gap-2">
          <LinkButton to="new">Create Team</LinkButton>
        </div>
      </div>

      <div className="card mb-4">
        <div className="label-text mb-2">Search</div>
        <Input
          type="text"
          value={name}
          onChange={(val) => {
            setName(val);
          }}
          placeholder="Search by name..."
          autoComplete="off"
        />
      </div>

      <div className="grid xl:grid-cols-2 gap-4">
        {teams.map((t) => {
          return (
            <Link
              className="card cursor-pointer"
              style={{ gridTemplateColumns: 'auto 2fr 1fr' }}
              data-clickable="true"
              to={t.id}
              key={t.id}
            >
              <div className="card-heading">{t.name}</div>

              <div className="card-subtle-text flex justify-between">
                <div>{`Created: ${formatDateTime(t.createdAt)}`}</div>

                <div className="flex gap-2 items-center">
                  <CoinsIcon className="button-icon" /> {`${t.remainingCredits}`}
                </div>
              </div>
            </Link>
          );
        })}
      </div>

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          page={pagination.variables.page}
          totalPages={pagination.pages}
        />
      </div>
    </div>
  );
};
