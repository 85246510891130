import { createId } from '@paralleldrive/cuid2';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Copy as CopyIcon, Trash as TrashIcon } from '@phosphor-icons/react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { LinkButton } from '../../../components/button/Button';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { Pagination } from '../../../components/Pagination';
import { formatDateTime } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { Table } from '../../../components/table/Table';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { useTeam } from '../../team/context/TeamContext';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { BodyType as DeleteApiKeyBody } from '../endpoints/DeleteApiKeyEndpoint';
import { ResponseType as ListApiKeysResponseType } from '../endpoints/ListApiKeysEndpoint';
import { captureException } from '@sentry/react';

const API_KEYS_TABLE_HEADERS = [
  {
    id: 'id',
    name: 'Title',
  },
  {
    id: 'creator',
    name: 'Creator',
  },
  {
    id: 'createdAt',
    name: 'Created at',
  },
  {
    id: 'lastUsedAt',
    name: 'Last used at',
  },
  {
    id: 'copy',
    name: 'Copy',
  },
  {
    id: 'delete',
    name: 'Delete',
  },
];

export const ApiKeysPage = () => {
  const { team } = useTeam();
  const [refreshToken, setRefreshToken] = useState(() => createId());
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const params = new URLSearchParams();
      params.set('page', pagination.variables.page.toString(10));
      params.set('take', variables.take.toString());
      params.set('teamId', team.id);

      const result = await fetchEndpointData<ListApiKeysResponseType>(`/api/v1/api-key/list?${params.toString()}`, {});
      return {
        data: result.docs ?? [],
        pages: result.pagination.pages,
      };
    },
    [team.id],
  );
  const pagination = usePagination({
    pageSize: 40,
    refreshToken,
    page: +(searchParams.get('page') ?? 0),
    fetcher: pageFetcher,
  });

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('page', pagination.variables.page.toString(10));
      return prev;
    });
  }, [pagination.variables.page]);

  const apiKeys = pagination.data ?? [];
  return (
    <div className="page-content">
      <PageHeader title="API Keys" />

      <div className="flex justify-between items-center mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Settings',
              to: '..',
            },
            {
              name: 'API Keys',
            },
          ]}
        />

        <div className="flex gap-2">
          <LinkButton to="new">Create API Key</LinkButton>
        </div>
      </div>

      {!!apiKeys.length && (
        <Table
          idKey="id"
          headers={API_KEYS_TABLE_HEADERS}
          data={apiKeys}
          mapData={(entry) => {
            return [
              entry.description,
              entry.creator.name,
              formatDateTime(entry.createdAt),
              entry.lastUsedAt ? formatDateTime(entry.lastUsedAt) : 'Never',
              <div
                className="cursor-pointer hover:text-link-color"
                onClick={() => {
                  navigator.clipboard.writeText(`api::${entry.id}::${entry.key}`);
                  toast.success('API key copied to clipboard');
                }}
              >
                <CopyIcon className="button-icon" />
              </div>,
              <ConfirmDialog
                triggerText={<TrashIcon className="button-icon" />}
                title="Delete api key"
                submitText="Delete"
                triggerVariant="destructive"
                submitVariant="destructive"
                description={`Are you sure you want to delete api key "${entry.description}"?`}
                onSubmit={async () => {
                  try {
                    const payload: DeleteApiKeyBody = {
                      apiKeyId: entry.id,
                    };
                    await fetchEndpointData('/api/v1/api-key/delete', {
                      method: 'DELETE',
                      body: payload,
                    });
                    setRefreshToken(createId());
                    toast.success('API Key has been deleted');
                  } catch (err) {
                    captureException(err);
                    toast.error('Could not delete api key: ' + getDisplayError(err));
                  }
                }}
              />,
            ];
          }}
        />
      )}

      {!apiKeys.length && !pagination.isFetching && (
        <div className="card">
          <div>No api keys found.</div>
        </div>
      )}

      {!apiKeys.length && pagination.isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          page={pagination.variables.page}
          totalPages={pagination.pages}
        />
      </div>
    </div>
  );
};
