import { useCallback, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Plus as PlusIcon } from '@phosphor-icons/react';

import { useTeam } from '@/app/team/context/TeamContext';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { formatDate } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { Pagination } from '../../../components/Pagination';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as WorkspacesResponseType } from '../endpoints/WorkspacesEndpoint';
import { IPaginationVariables, usePagination } from '@/hooks/usePagination';

export const WorkspacesPage = () => {
  const { team } = useTeam();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const query = new URLSearchParams();
      query.set('teamId', team.id);
      query.set('page', variables.page.toString());
      query.set('take', variables.take.toString());
      const result = await fetchEndpointData<WorkspacesResponseType>(`/api/v1/workspace/list?${query.toString()}`);
      return {
        data: result.docs,
        pages: result.pagination.pages,
      };
    },
    [team.id],
  );
  const pagination = usePagination({
    pageSize: 40,
    page: +(searchParams.get('page') ?? 0),
    fetcher: pageFetcher,
  });

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('page', pagination.variables.page.toString());
      return prev;
    });
  }, [pagination.variables.page]);

  const workspaces = pagination.data ?? [];
  const title = 'Workspaces';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />
      </div>

      {!workspaces.length && pagination.isFetching ? (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          <Link
            to="new"
            className="card flex justify-center items-center gap-2 text-lg font-medium"
            data-clickable="true"
          >
            <div className="flex-shrink-0">
              <PlusIcon />
            </div>
            Add workspace
          </Link>

          {workspaces.map((v) => {
            return (
              <Link to={v.id} key={v.id} className="card" data-clickable="true">
                <div className="text-lg font-medium">{v.name}</div>
                <div>{formatDate(v.createdAt)}</div>
              </Link>
            );
          })}
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          page={pagination.variables.page}
          totalPages={pagination.pages}
        />
      </div>
    </div>
  );
};
