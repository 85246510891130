import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/Pagination';
import { SpinnerBlock } from '../../../components/Spinner';
import { ITableHeader, Table } from '../../../components/table/Table';
import { formatDateTime } from '../../../utils/date';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { ResponseType as AuditLogsResponseType } from '../endpoints/ListInternalAuditLogsEndpoint';
import { IPaginationVariables, usePagination } from '@/hooks/usePagination';

const TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Action',
  },
  {
    id: 'description',
    name: 'Description',
  },
  {
    id: 'creator',
    name: 'Creator',
  },
  {
    id: 'createdAt',
    name: 'Created at',
  },
];

export const InternalAuditLogsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFetcher = useCallback(async (variables: IPaginationVariables) => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', variables.page.toString());
    searchParams.set('take', variables.take.toString());

    const result = await fetchEndpointData<AuditLogsResponseType>(
      `/api/v1/internal/audit-log/list?${searchParams.toString()}`,
    );
    return {
      data: result.docs ?? [],
      pages: result.pagination.pages,
    };
  }, []);
  const pagination = usePagination({
    page: +(searchParams.get('page') || 0),
    pageSize: 40,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('page', '' + pagination.variables.page);
      return prev;
    });
  }, [pagination.variables.page]);

  const entries = pagination.data ?? [];
  const title = 'Audit Logs';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Backoffice',
              to: '/app/internal',
            },
            {
              name: title,
            },
          ]}
        />
      </div>

      {(!!entries.length || !pagination.isFetching) && (
        <Table
          idKey="id"
          headers={TABLE_HEADERS}
          data={entries}
          mapData={(entry) => {
            return [
              <span className="whitespace-nowrap">{entry.action}</span>,
              <span className="whitespace-nowrap">{entry.description}</span>,
              <span className="whitespace-nowrap">{entry.email}</span>,
              <span className="whitespace-nowrap">{formatDateTime(entry.createdAt)}</span>,
            ];
          }}
        />
      )}

      {!entries.length && pagination.isFetching && (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={pagination.hasPrevious}
          previous={pagination.previous}
          hasNext={pagination.hasNext}
          next={pagination.next}
          isFetching={pagination.isFetching}
          page={pagination.variables.page}
          totalPages={pagination.pages}
        />
      </div>
    </div>
  );
};
