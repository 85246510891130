import { PdfPreview } from '../../../components/PdfPreview';

export const PREVIEW_MIMETYPES = new Set(['application/pdf']);

export interface IFilePreviewProps {
  title: string;
  mimetype: string;
  fileUrl: string;
  pageNumber?: number | null;
}

export const FilePreview: React.FC<IFilePreviewProps> = (props) => {
  const { title, mimetype, fileUrl, pageNumber } = props;

  if (mimetype === 'application/pdf') {
    return <PdfPreview fileUrl={fileUrl} title={title} page={pageNumber} />;
  }

  return <div>File not supported.</div>;
};
